.primary__0ijDL,
.link__Z0XDr {
  color: #0e0e0e;
  font-weight: 600;
  font-family: 'circular', serif;
  font-size: inherit;
  /* stylelint-disable-line sh-waqar/declaration-use-variable */
  line-height: normal;
  cursor: pointer;
  text-decoration: underline;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
  text-transform: capitalize;
}
.primary__0ijDL:hover,
.link__Z0XDr:hover {
  color: #4a4a4a;
  text-decoration: underline;
}
.primary__0ijDL:active,
.link__Z0XDr:active {
  color: #4a4a4a;
  text-decoration: underline;
}
.primary__0ijDL.noTextTransform__eWtVS,
.link__Z0XDr.noTextTransform__eWtVS {
  text-transform: none;
}
.newarrow__JoaZd {
  vertical-align: middle;
  padding-right: 0.2em;
  padding-bottom: 0.1em;
}
.newarrow__JoaZd::after {
  left: 0.2em;
  position: relative;
  margin-right: -1.5em;
}
.secondary__hLtpN {
  cursor: pointer;
  color: #0f5c82;
  text-decoration: underline;
  text-transform: capitalize;
}
.secondary__hLtpN.noTextTransform__eWtVS {
  text-transform: none;
}
.secondary__hLtpN:hover {
  color: #4a4a4a;
}
.tertiary__Csl\+A {
  cursor: pointer;
  color: #ffffff;
  text-decoration: underline;
  text-transform: capitalize;
}
.tertiary__Csl\+A.noTextTransform__eWtVS {
  text-transform: none;
}
.tertiary__Csl\+A:hover {
  color: #dbdbdb;
}
.disabled__FvodP {
  cursor: pointer;
  color: #b7b7b7;
  text-decoration: underline;
  text-transform: capitalize;
  cursor: auto;
}
.disabled__FvodP.noTextTransform__eWtVS {
  text-transform: none;
}
.disabled__FvodP:hover {
  color: #b7b7b7;
}
.disabled__FvodP:hover,
.disabled__FvodP:active,
.disabled__FvodP:visited,
.disabled__FvodP::after,
.disabled__FvodP::before {
  color: #b7b7b7;
}
.active__y-P5L {
  text-decoration: underline;
}
.danger__DWFbI {
  cursor: pointer;
  color: #c7305b;
  text-decoration: underline;
  text-transform: capitalize;
}
.danger__DWFbI.noTextTransform__eWtVS {
  text-transform: none;
}
.danger__DWFbI:hover {
  color: #cf4f74;
}
.black__QjoqZ {
  color: #0e0e0e;
}
.inline__du2xX {
  letter-spacing: normal;
  text-transform: none;
}
.subtle__CnJSR {
  font-weight: 400;
}
.bold__JRDVR {
  font-weight: 700;
}
.inherit__p2UCf {
  font-size: inherit;
}
.extraSmall__Gw35H {
  font-size: 12px;
}
.smaller__d0ozB {
  font-size: 14px;
}
.sm__LnBPi,
.small__G3KzI {
  font-size: 16px;
}
.md__7QnJh,
.regular__z3O8O {
  font-size: 20px;
}
.large__CIpuD,
.lg__5joog {
  font-size: 24px;
}
.larger__8A4kY,
.xl__33hca {
  font-size: 32px;
}
.semiHuge__4RgaB {
  font-size: 40px;
}
.huge__RfYyT {
  font-size: 56px;
}
.button__3VZRJ {
  outline: none;
  border: none;
  padding: 0;
  background-color: transparent;
}
.button__3VZRJ:focus-visible {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: 1px;
}
.noUnderline__bWO-u:not(:hover) {
  text-decoration: none;
}
