// Zola UI styles
// @import '~@zola/zola-ui/build/zola-ui.css';
@import '~@zola/zola-ui/src/styles/common/_reset.less';
@import '~@zola/zola-ui/src/styles/common/_scaffolding.less';
@import '~@zola/zola-ui/src/styles/common/_type.less';
// @import './_forms.less';
@import './zolaUiFixes.less';

// The Emotion styling for the ToastV2 component doesn't import correctly
@import '~@zola/zola-ui/src/components/Toasts/Toasts.less';
@import '~@zola/zola-ui/src/components/Toasts/Toast.less';

// The Less loader doesn't import these for some reason
@import '~@zola/zola-ui/src/components/Carousel/slick.css';
@import '~react-day-picker/lib/style.css'; // Comment out when symlinking zola-ui
// @import '~@zola/zola-ui/node_modules/react-day-picker/lib/style.css'; // Use this when symlinking zola-ui

@import (reference) '~@zola/zola-ui/src/styles/baby.less';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  width: 100%;
}

:root {
  .baby-styles();
}

/* Custom Circular font for body - was added to the zola-web-assets bucket */
@font-face {
  font-family: 'circular';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Regular.woff2')
      format('woff2'),
    url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Regular.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'circular';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Medium.woff2')
      format('woff2'),
    url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Medium.woff')
      format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'circular';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-MediumItalic.woff2')
      format('woff2'),
    url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-MediumItalic.woff')
      format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
